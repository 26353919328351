

import LoadingOverlay from "../loading-overlay.vue";
import SaveButton from "../save-button.vue";
import PressReleasesTable from "./press-releases-table.vue";

export default {
    name: "IntroEmailDraftModal",
    components: {LoadingOverlay, PressReleasesTable, SaveButton},
    props: {
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            generating: false,
        }
    },
    methods: {
        submit() {
            this.generating = true;
            this.$axios.post('/api/emails/create_company_intro_draft/' + this.companyId, {
                news: this.$refs.pressReleasesTable.selectedRows,
            }).then(response => {
                this.$router.push('/emails/' + response.data.id);
            });
        },
    }
}
