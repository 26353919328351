
/**
 * This is an overview of who owns shares of this company
 * @see https://financialmodelingprep.com/developer/docs#Institutional-Holders
 */

import moment from "moment";

export default {
    name: "InstitutionalHoldersCard",
    components: {},
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            companyWorkingCopy: {},
            institutionalHolders: [],
            rowsUpdated: moment().unix(),
            config: {
                columns: [
                    {
                        headline: "Symbol",
                        sort: true
                    }, {
                        headline: "Holder",
                        sort: true
                    }, {
                        headline: "Type",
                        sort: true
                    }, {
                        headline: "Count",
                        sort: true
                    }, {
                        headline: "Change",
                        sort: true
                    }, {
                        headline: "Weight",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Market Value",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Date Reported",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Market List",
                        sort: true,
                        breakpoint: "xl",
                    },
                ],
                pagination: 10
            },
            sortOrder: [{
                index: 3,
                asc: false,
            }],
            hideUnknown: false,
            tableLoading: false,
        }
    },
    computed: {
        institutionalHolderRows() {
            return this.institutionalHolders.map(item => {
                return [
                    {
                        text: item.ticker,
                        sortValue: item.ticker
                    }, {
                        html: item.id
                            ? `<a href="${this.$axios.defaults.baseURL}/engine/companies/${item.id}" target="_blank">${this.escapeHtml(item.investorName)}</a>`
                            : this.escapeHtml(item.investorName),
                        sortValue: item.investorName
                    }, {
                        text: item.putCallShare,
                        sortValue: item.putCallShare
                    }, {
                        text: this.nFormatter(item.sharesNumber, 1, true),
                        sortValue: item.sharesNumber
                    }, {
                        text: parseInt(item.changeInSharesNumber) >= 0
                            ? this.nFormatter(item.changeInSharesNumber, 1)
                            : '-' + this.nFormatter(-1 * item.changeInSharesNumber, 1),
                        classes: parseInt(item.changeInSharesNumber) >= 0 ? "text-success" : "text-danger",
                        sortValue: item.changeInSharesNumber
                    }, {
                        text: parseFloat(item.weight).toFixed(4) + ' %',
                        sortValue: item.weight
                    }, {
                        text: this.nFormatter(item.marketValue, 1, true),
                        sortValue: item.marketValue
                    }, {
                        text: moment(item.date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY"),
                        sortValue: item.date
                    }, {
                        type: "marketListMembers",
                        data: item.marketListMembers,
                    }
                ]
            });
        },
    },
    watch: {
        institutionalHolders() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        },
        hideUnknown() {
            this.loadInstitutionalHolders();
        }
    },
    mounted() {
        this.loadInstitutionalHolders();
        this.resetWorkingCopy();
    },
    methods: {
        loadInstitutionalHolders() {
            this.tableLoading = true;
            this.$axios.get(`/api/companies/institutional_holders/${this.company.id}?${this.buildQueryString({"hideUnknown": this.hideUnknown})}`)
                .then((response) => {
                    this.institutionalHolders = response.data;
                }).finally(() => {
                    this.tableLoading = false;
                });
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
        downloadInstitutionalHolders() {
            window.open(
                this.$axios.defaults.baseURL +
                    `/api/companies/excel_export/institutional_holders/${this.company.id}?${this.buildQueryString({"hideUnknown": this.hideUnknown})}`,
                '_blank'
            ).focus();
        },
        createContactList() {
            this.tableLoading = true;
            this.$axios.post(`/api/companies/contact_list_for_institutional_holders/${this.company.id}`).then(response => {
                const toastId = this.generateUUID();

                if (response.data.id) {
                    this.addToast({
                        type: "success",
                        title: "Contact List",
                        message: response.data.message,
                        id: toastId,
                    });

                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.$router.push({path: '/contacts/contact-groups', query: {contactListId: response.data.id}});
                    });

                } else {
                    this.addToast({
                        type: "warning",
                        title: "Contact List",
                        message: response.data.message,
                        id: toastId,
                    });

                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }
            }).finally(() => {
                this.tableLoading = false;
            });
        },
    }
}
