
import moment from "moment";

export default {
    name: "InternationalFilingsCard",
    components: {},
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            resultType: ['Yearly', 'Quarterly'],
            selectedType: 'Quarterly',
            companyWorkingCopy: {},
            internationalFilings: [],
            rowsUpdated: moment().unix(),
            config: {
                columns: [
                    {
                        headline: "Filling Date",
                        sort: true
                    }, {
                        headline: "Accepted Date",
                        breakpoint: "all"
                    }, {
                        headline: "Period",
                        breakpoint: "all"
                    }, {
                        headline: "Revenue",
                        align: "end",
                    }, {
                        headline: "Cost of Revenue",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "Gross Profit",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "Gross Profit Margin",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "Research and Development Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "General and Administrative Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Selling and Marketing Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Selling General and Administrative Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Other Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Operating Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Cost and Expenses",
                        breakpoint: "all"
                    }, {
                        headline: "Interest Expense",
                        breakpoint: "all"
                    }, {
                        headline: "Depreciation and Amortization",
                        breakpoint: "all"
                    }, {
                        headline: "EBITDA",
                        align: "end"
                    }, {
                        headline: "EBITDA Margin",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "Operating Income",
                        breakpoint: "all"
                    }, {
                        headline: "Operating Income Ratio",
                        breakpoint: "all"
                    }, {
                        headline: "Total Other Income Expenses Net",
                        breakpoint: "all"
                    }, {
                        headline: "Income before Tax",
                        breakpoint: "all"
                    }, {
                        headline: "Income before Tax Ratio",
                        breakpoint: "all"
                    }, {
                        headline: "Income Tax Expense",
                        breakpoint: "all"
                    }, {
                        headline: "Net Income",
                        breakpoint: "all"
                    }, {
                        headline: "Net Income Ratio",
                        breakpoint: "all"
                    }, {
                        headline: "EPS",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "EPS Diluted",
                        align: "end",
                        breakpoint: "xl"
                    }, {
                        headline: "Weighted Average Shs Out",
                        breakpoint: "all"
                    }, {
                        headline: "Weighted Average Shs Out Dil",
                        breakpoint: "all"
                    }
                ],
                pagination: 10
            },
            sortOrder: [{
                index: 0,
                asc: false,
            }],
            isTableLoading: false,
        }
    },
    computed: {
        fromDate() {
            if (this.internationalFilings && this.internationalFilings[0]) {
                return moment(this.internationalFilings[0].date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY")
            }
            return '';
        },
        internationalFilingRows() {
            return this.internationalFilings.map(item => {
                return [
                    {
                        text: moment(item.fillingDate, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY"),
                        sortValue: item.fillingDate
                    }, {
                        text: moment(item.acceptedDate, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY"),
                        sortValue: item.acceptedDate
                    }, {
                        text: item.period
                    }, {
                        text: this.nFormatter(item.revenue, 2, false, 21)
                    }, {
                        text: this.nFormatter(item.costOfRevenue)
                    }, {
                        text: this.nFormatter(item.grossProfit)
                    }, {
                        text: this.nFormatter(item.grossProfitRatio * 100, 2, true) + "%"
                    }, {
                        text: this.nFormatter(item.researchAndDevelopmentExpenses)
                    }, {
                        text: this.nFormatter(item.generalAndAdministrativeExpenses)
                    }, {
                        text: this.nFormatter(item.sellingAndMarketingExpenses)
                    }, {
                        text: this.nFormatter(item.sellingGeneralAndAdministrativeExpenses)
                    }, {
                        text: this.nFormatter(item.otherExpenses)
                    }, {
                        text: this.nFormatter(item.operatingExpenses)
                    }, {
                        text: this.nFormatter(item.costAndExpenses)
                    }, {
                        text: this.nFormatter(item.interestExpense)
                    }, {
                        text: this.nFormatter(item.depreciationAndAmortization)
                    }, {
                        text: this.nFormatter(item.ebitda, 2, false, 21)
                    }, {
                        text: this.nFormatter(item.ebitdaratio * 100, 2, true) + "%"
                    }, {
                        text: this.nFormatter(item.operatingIncome)
                    }, {
                        text: this.nFormatter(item.operatingIncomeRatio, 2, true)
                    }, {
                        text: this.nFormatter(item.totalOtherIncomeExpensesNet)
                    }, {
                        text: this.nFormatter(item.incomeBeforeTax)
                    }, {
                        text: this.nFormatter(item.incomeBeforeTaxRatio, 2, true)
                    }, {
                        text: this.nFormatter(item.incomeTaxExpense)
                    }, {
                        text: this.nFormatter(item.netIncome)
                    }, {
                        text: this.nFormatter(item.netIncomeRatio, 2, true)
                    }, {
                        text: this.nFormatter(item.eps, 2, true)
                    }, {
                        text: this.nFormatter(item.epsdiluted, 2, true)
                    }, {
                        text: this.nFormatter(item.weightedAverageShsOut)
                    }, {
                        text: this.nFormatter(item.weightedAverageShsOutDil)
                    }
                ]
            });
        },
    },
    watch: {
        internationalFilings() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        },
        selectedType() {
            this.loadInternationalFilings();
        }
    },
    mounted() {
        this.loadInternationalFilings();
        this.resetWorkingCopy();
    },
    methods: {
        loadInternationalFilings() {
            this.isTableLoading = true;
            if (this.selectedType === "Yearly") {
                this.$axios.get("/api/companies/stock_info/" + this.company.id + "/income-statement")
                    .then((response) => {
                        this.internationalFilings = response.data;
                    })
                    .finally(() => {
                        this.isTableLoading = false;
                    });
            } else {
                this.$axios.get("/api/companies/stock_info/" + this.company.id + "/income-statement/?period=quarter")
                    .then((response) => {
                        this.internationalFilings = response.data;
                    })
                    .finally(() => {
                        this.isTableLoading = false;
                    });
            }
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
    }
}
