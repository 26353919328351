

import {mapActions, mapGetters} from "vuex";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import fileDownload from "../../mixins/file-download";
import modals from "../../mixins/modals";
import contactBulkOperationForm from "../contacts/contact-bulk-operation-form";
import {INTEREST_LIST_STATUS} from "../../mixins/interest-list-status";
import companyBulkOperationForm from "./company-bulk-operation-form";


export default {
    name: "InterestListBulkOperationModal",
    components: {LoadingSpinner, SaveButton, contactBulkOperationForm, companyBulkOperationForm},
    mixins: [fileDownload, modals],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        preSelectedRowIds: {
            type: Array,
            default: null,
        },
        fromContact: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            operation: "delete",
            running: false,
            loading: false,
            contactLoading: false,
            companyLoading: false,
            bulkInterestListItems: [],
            bulkContacts: [],
            bulkCompanies: [],
            isInterestListOption: true,
            contactFilters: {},
            selectedStatusForInterestList: null,
            INTEREST_LIST_STATUS,
            commentForInterestList: null,

        };
    },
    computed: {
        ...mapGetters({
            bulkInterestListItemsFiltersHash: 'app/bulkInterestListItemsFiltersHash',
            bulkContactFiltersHash: 'app/bulkContactFiltersHash',
        }),
        bulkOptions() {
            return [
                {value: 'delete', label: 'Remove from Interest List'},
                {value: 'edit_comment', label: 'Edit Comment'},
                {value: 'edit_status', label: 'Edit Status'}
            ]
        },
    },
    watch: {
        operation() {
            // Add stuff if needed
        }
    },
    created() {
        this.loadBulkInterestListItems();
    },
    methods: {
        ...mapActions({
            setBulkInterestListItemsFiltersHash: "app/setBulkInterestListItemsFiltersHash",
            setBulkContactFiltersHash: "app/setBulkContactFiltersHash",
        }),

        submitForm() {
            const iliIds = this.bulkInterestListItems.map(ili => ili.id)
            switch (this.operation) {
                case "delete":
                    this.deleteBulk();
                    break;
                case "edit_comment":
                    this.$axios.post('/api/interest_list/edit_bulk_contacts/', {
                        comment: this.commentForInterestList,
                        status: null,
                        iliIds
                    }).then((re) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Edited",
                            message: re.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.$emit('submitted', true);

                    });
                    break
                case "edit_status":
                    this.$axios.post('/api/interest_list/edit_bulk_contacts/', {
                        comment: null,
                        status: this.selectedStatusForInterestList,
                        iliIds
                    }).then((re) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Edited",
                            message: re.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }).finally(() => {
                        this.$emit('submitted', true);

                    });
                    break
                default:
                    console.error(`Operation ${this.operation} is not allowed.`);
            }
        },
        deleteBulk() {
            const toDeleteIds = this.bulkInterestListItems.map(item => item.id);
            this.$axios.post("/api/interest_list/remove_interest_list_item", {
                toDeleteIds
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Item Removed",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.$emit('submitted', true);

            });
        },
        async loadBulkInterestListItems() {
            this.loading = true;
            this.contactLoading = true;
            this.companyLoading = true;

            const responseFilters = await this.$axios.post("/api/interest_lists/save_bulk_filters", {filters: this.filters});

            this.setBulkInterestListItemsFiltersHash(responseFilters.data.hash);
            this.setBulkContactFiltersHash(responseFilters.data.hash_contacts)

            const interestListItemsPromise = this.$axios.get("/api/interest_lists/bulk_interest_list_items", {
                params: {
                    hash: this.bulkInterestListItemsFiltersHash
                }
            }).then(response => {
                this.bulkInterestListItems = response.data["hydra:member"];
                this.contactFilters = {
                    ids: this.bulkInterestListItems.map(InterestListItem =>
                        InterestListItem.contact.id)
                }
            }).finally(() => {
                this.loading = false;
            });

            let companyPromise
            let contactsPromise

            if (this.fromContact) {
                const companyResponseFilters = await this.$axios.post("/api/companies/save_bulk_filters", {filters: {companyIds: responseFilters.data.companyIds}});
                const hash = companyResponseFilters.data.hash;

                companyPromise = this.$axios.get("/api/companies/bulk_companies", {
                    params: {
                        hash
                    }
                }).then(response => {
                    this.bulkCompanies = response.data["hydra:member"];
                }).finally(() => {
                    console.log(this.bulkCompanies);
                    this.companyLoading= false;
                });
            } else {
                contactsPromise = this.$axios.get("/api/contacts/bulk_contacts", {
                    params: {
                        hash: this.bulkContactFiltersHash
                    }
                }).then(response => {
                    this.bulkContacts = response.data["hydra:member"];
                })
            }

            Promise.all([this.fromContact ? companyPromise : contactsPromise, interestListItemsPromise]).finally(() => {
                this.contactLoading = false;
            });
        },
        submitted() {
            this.$emit("submitted", true);
        },
        exportAsExcel() {
            this.$emit('export-as-excel', this.contactFilters);
        },
    }
}
