

import moment from "moment";
import {INTEREST_LIST_STATUS, OPTION_INTERESTED} from "../../mixins/interest-list-status";
import modals from "../../mixins/modals";


export default {
    name: "InterestListModal",
    components: {},
    mixins: {modals},
    props: {
        initialCompany: {
            type: Object,
            default: null,
        },
        initialContact: {
            type: Object,
            required: null,
        },
        interestListEdit: {
            type: Object,
            default: null
        },
        interestListModalTitle: {
            type: Boolean,
            default: true,
        }


    },
    data() {
        return {
            // id: null,
            saving: false,
            formName: "interest-list-item-form",
            companyWorkingCopy: null,
            // interestListEdit: null,
            comment: "",
            status: "",
            contact: null,
            listId: null,
            loading: true,
            INTEREST_LIST_STATUS,


        }
    },
    computed: {
        isNew() {

            return this.interestListEdit === null;

        }

    },
    watch: {
        interestListEdit: {
            handler(val) {
                this.resetWorkingCopy();
            },
            immediate: true,
            deep: true
        },
        initialCompany: {
            handler(val) {
                this.resetWorkingCopy();
            },
            immediate: true,
            deep: true
        },
        initialContact: {
            handler(val) {
                this.resetWorkingCopy();
            },
            immediate: true,
            deep: true
        },
    },
    mounted() {
        this.resetWorkingCopy();
    },
    methods: {
        // This will reset our form
        resetWorkingCopy() {
            if (this.interestListEdit) {

                this.companyWorkingCopy = structuredClone(this.interestListEdit.company);
                this.contact = structuredClone(this.interestListEdit.contact.nameWithCompany);
                // this.contacts = this.interestListEdit;
                this.comment = this.interestListEdit.comment;
                this.status = this.interestListEdit.status;

            } else {
                this.listId = null;
                if (this.initialCompany) {
                    this.companyWorkingCopy = structuredClone(this.initialCompany);
                } else {
                    this.companyWorkingCopy = null;
                }
                if (this.initialContact) {
                    this.contact = this.initialContact
                } else {
                    this.contact = null
                }

                this.comment = "";
                this.status = OPTION_INTERESTED;

            }

            this.loading = false
            this.$nextTick(() => {
                this.companyUpdated = moment().unix();
            })
        },

        submitForm() {
            this.saving = true;

            const contactIds = this.contact.id;
            // const companyId = this.companyWorkingCopy.id;

            const finalObject = {
                contactIds,
                companyId: this.companyWorkingCopy.id,
                comment: this.comment,
                status: this.status,
            };

            if (this.interestListEdit) {
                finalObject.id = this.interestListEdit.id
                this.$axios.patch(`/api/interest_list_items/${finalObject.id}`, finalObject, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },

                }).then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Interest List Item Edited",
                        message: "Interest List Item successfully changed",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    // this.isNew = false;
                    this.saving = false;
                    this.$emit('updated');
                });
            } else {
                return this.$axios.post(`/api/interest_list/add_contact`, finalObject).then((response) => {
                    const message = response.data.message;

                    const toastId = this.generateUUID();
                    this.addToast({
                        message,
                        type: "success",
                        title: "Interest List Item Created",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.saving = false;
                    this.$emit('updated');
                })

            }
        },
    }

}
